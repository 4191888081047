@keyframes gradientLoop {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-button {
  background-color: #fce055;
  background-image: linear-gradient(
    319deg,
    #fce055 0%,
    #256eff 37%,
    #46237a 100%
  );
  /* background: linear-gradient(270deg, #0892d0, #4b0082); */
  background-size: 400% 400%;
  animation: gradientLoop 15s ease infinite;
  margin-left: 1%;
  padding: 10px;
  border-radius: 0.5rem;
  display: inline-block;
  text-align: center;
  color: white;
}
