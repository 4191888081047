.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.buttoncss {
  max-width: 100;
  max-height: 100;
  margin: 10px;
}
.bg {
  background-image: url(./assets/Winning\ the\ battle\ against\ Coronavirus-amico.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}
.content {
  align-items: center;
  font-family: sans-serif;
  justify-content: center;
  text-align: center;
  top: 500;
  bottom: 500;
  position: relative;
}
.textstyle {
  font-size: clamp(2rem, 3vw, 6rem);
  font-weight: bold;
}
.buttonstyle {
  font-size: larger;
}
.donatestyle {
  padding: 15px;
  background-image: linear-gradient(90deg, #bbdefb, #1976d2);
}
.joinusstyle {
  padding: 15px;
  background-image: linear-gradient(90deg, #ffff00, #ffff00);
}
.header {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 20px 10px;
}
.footer {
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #34495e;
  color:#fff;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
